import React from 'react'
import { css } from '@emotion/react'
import { HeaderNav } from '@/components/section/header/header-nav'
import { headerNavList } from '@/constants/header/3rdeconomy'
import { FooterBottom } from '@/components/section/footer/footer-bottom'
import { footerBottomList } from '@/constants/footer/3rdeconomy'
import header_3rdeconomy_logo from '@/images/3rdeconomy/logo-corporate.svg'
import color from '@/constants/color'
import { mq } from '@/components/media/media'
import gray_bg_02 from '@/images/gray_bg_02.jpg'
import { BreadcrumbNav } from '@/components/common/breadcrumbNav'

type PageLayoutProps = {
  breadcrumbs: string
  breadcrumbsPath: string
  title: string
  subTitle: string
  children?: React.ReactNode
}

export const ThirdRdeconomyPageLayout = (
  props: PageLayoutProps
): JSX.Element => {
  const { breadcrumbs, breadcrumbsPath, title, subTitle, children } = props

  return (
    <>
      <HeaderNav
        css={cssHeaderNav}
        url="/group/3rdeconomy"
        imgSrc={header_3rdeconomy_logo}
        alt="3rdeconomy"
        navList={headerNavList}
        gradientColor={[color.green2, color.blue8]}
        btnFontStyleCss={cssBtnFontStyle}
        arrowFillColor={color.white1}
        headerLogoCss={cssHeaderLogoSize}
        breakpoint={768}
      />
      <div css={cssHeadingLayout}>
        <BreadcrumbNav breadcrumbs={breadcrumbs} path={breadcrumbsPath} />
        <h1 css={cssHeadingFirst}>
          <span>{title}</span>
          <br />
          <span>{subTitle}</span>
        </h1>
      </div>
      <section css={cssContentsLayout}>{children}</section>
      <FooterBottom footerBottomList={footerBottomList} />
    </>
  )
}

const cssHeaderNav = css`
  padding: 15px 16px;
  font-family: 'Jost', 'Noto Sans JP', 'Yu Gothic Medium', '游ゴシック Medium',
    YuGothic, '游ゴシック体', 'ヒラギノ角ゴ Pro W3', 'メイリオ',
    'Hiragino Kaku Gothic Pro', Meiryo, Osaka, 'ＭＳ Ｐゴシック', 'MS PGothic',
    sans-serif;
  box-shadow: 0 3px 6px ${color.gray1};
  ${mq.pc} {
    box-shadow: 0 0 16px ${color.gray1};
  }
`

const cssHeaderLogoSize = css`
  width: 44px;
  ${mq.mid} {
    width: 60px;
  }
`

const cssBtnFontStyle = css`
  font-weight: 600;
  color: ${color.white1};
`

const cssHeadingLayout = css`
  padding: 84px 16px 24px;
  font-family: 'Lato', 'Noto Sans JP', 'Yu Gothic Medium', '游ゴシック Medium',
    YuGothic, '游ゴシック体', 'ヒラギノ角ゴ Pro W3', 'メイリオ',
    'Hiragino Kaku Gothic Pro', Meiryo, Osaka, 'ＭＳ Ｐゴシック', 'MS PGothic',
    sans-serif;
  background-image: url(${gray_bg_02});
  background-size: cover;
  ${mq.pc} {
    padding: 152px 16px 48px;
    background-size: contain;
  }
  > ul {
    margin-bottom: 24px;
  }
`

const cssContentsLayout = css`
  padding: 48px 16px 60px;
  font-family: 'Lato', 'Noto Sans JP', 'Yu Gothic Medium', '游ゴシック Medium',
    YuGothic, '游ゴシック体', 'ヒラギノ角ゴ Pro W3', 'メイリオ',
    'Hiragino Kaku Gothic Pro', Meiryo, Osaka, 'ＭＳ Ｐゴシック', 'MS PGothic',
    sans-serif;
  background-color: ${color.white1};
  ${mq.pc} {
    max-width: 1080px;
    padding: 120px 16px;
    margin: 0 auto;
  }
`

const cssHeadingFirst = css`
  color: ${color.black2};
  text-align: center;
  span {
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1.6px;
    ${mq.pc} {
      font-size: 28px;
      line-height: 1.4;
      letter-spacing: 2.8px;
    }
    &:last-child {
      font-size: 28px;
      letter-spacing: 2.8px;
      ${mq.pc} {
        font-size: 48px;
        letter-spacing: 4.8px;
      }
    }
  }
`
