import React from 'react'

import { ThirdRdeconomyPageLayout } from '@/components/3rdeconomy/3rdeconomyPageLayout'
import { tempNewsList } from '@/constants/news/3rdeconomy'
import { ThirdrdeconomyHead } from '@/components/3rdeconomy/3rdeconomyHead'
import { NewsList } from '@/components/common/news'

export default function ThirdrdeconomyNews(): JSX.Element {
  return (
    <>
      <ThirdrdeconomyHead title="株式会社3rd Economy" url="/group/3rdeconomy" />
      <ThirdRdeconomyPageLayout
        title="お知らせ"
        subTitle="NEWS"
        breadcrumbs="GROUP/3rd Economy/NEWS"
        breadcrumbsPath="/group/3rdeconomy/news"
      >
        <NewsList newsList={tempNewsList} isPage />
      </ThirdRdeconomyPageLayout>
    </>
  )
}
