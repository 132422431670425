import React from 'react'
import { Helmet } from 'react-helmet'
import '@/styles/3rdeconomy/styleCo.scss'

import favicon from '@/images/3rdeconomy/favicon.ico'
import appleIcon from '@/images/3rdeconomy/apple-touch-icon.png'

type Props = {
  title: string
  url: string
}

export const ThirdrdeconomyHead = (props: Props): JSX.Element => {
  const { title, url } = props

  return (
    <Helmet>
      <html lang="ja" />
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width,initial-scale=1" />
      <title>{title}</title>
      <meta
        name="description"
        content="3rd Economyでは、ブロックチェーンを活用した新たな資産運用体験の創出をビジョンとして「web3×FinTech領域」の事業展開を目指します。"
      />
      <meta property="og:title" content={title} />
      <meta property="og:url" content={`https://relic-holdings.co.jp/${url}`} />
      <meta
        property="og:image"
        content="https://relic-holdings.co.jp/OGP_1200*630_3rdeconomy.png"
      />
      <meta
        property="og:description"
        content="3rd Economyでは、ブロックチェーンを活用した新たな資産運用体験の創出をビジョンとして「web3×FinTech領域」の事業展開を目指します。"
      />
      <meta property="og:type" content="website" />
      <meta property="og:locale" content="ja_JP" />
      <meta property="og:site_name" content={title} />
      <meta name="twitter:card" content="summary_large_image" />
      <link rel="icon" href={favicon} />
      <link
        rel="apple-touch-icon-precomposed"
        href={appleIcon}
        sizes="180x180"
      />
    </Helmet>
  )
}
